import React from 'react';
import leftFont from './assets/left.png';
import rightFont from './assets/right.png';
import grandeDame from './assets/GrandeDame_TessaTaett.png';
import './App.css';
import { Box, Flex, Image, Text } from 'rebass';
import { useBreakpointIndex } from '@theme-ui/match-media';

function App() {
  const index = useBreakpointIndex();

  if (index > 0) {
    return (
      <div className="App">
        <div className="Content">
          <Flex alignItems="center">
            <Box textAlign="right" className="fade-in">
              <Image src={leftFont} width="60%" alt="logo_left" />
            </Box>
            <Box>
              <Image src={grandeDame} alt="grand_dame" />
            </Box>
            <Box textAlign="left" className="fade-in">
              <Image src={rightFont} width="60%" alt="logo_right" />
            </Box>
          </Flex>
          <Flex alignItems="center" className="fade-in-later">
            <Text fontSize={[1, 1, 1]} color="primary">
              Content coming soon!
            </Text>
          </Flex>
        </div>
      </div>
    );
  } else {
    return (
      <div className="App">
        <div className="Content">
          <Flex alignItems="center">
            <Box>
              <Image src={grandeDame} alt="logo_left" />
            </Box>
          </Flex>
          <Flex alignItems="center" marginBottom={4}>
            <Box textAlign="right" marginRight={4} className="fade-in">
              <Image src={leftFont} width="60%" alt="grande_dame" />
            </Box>
            <Box textAlign="left" className="fade-in">
              <Image src={rightFont} width="60%" alt="logo_right" />
            </Box>
          </Flex>
          <Flex alignItems="center" className="fade-in-later">
            <Text fontSize={[1, 1, 1]} color="primary">
              Content coming soon!
            </Text>
          </Flex>
        </div>
      </div>
    );
  }
}

export default App;
