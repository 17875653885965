import { Theme } from '@theme-ui/css';

export const theme: Theme = {
  fonts: {
    body: 'Noto Sans Mono , monospace',
    heading: 'Noto Sans Mono , monospace',
    monospace: 'Noto Sans Mono , monospace',
  },
  breakpoints: ['40em', '52em', '64em'],
};
